html,
body {
  font-family: "Open Sans";
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
